/* tslint:disable */
/* eslint-disable */
export enum TripState {
  Started = 'Started',
  WaitForLoading = 'WaitForLoading',
  Loading = 'Loading',
  LoadingCompleted = 'LoadingCompleted',
  Transporting = 'Transporting',
  WaitForDropOff = 'WaitForDropOff',
  DroppingOff = 'DroppingOff',
  DropOffCompleted = 'DropOffCompleted',
  Confirmed = 'Confirmed',
  Returning = 'Returning',
  Ended = 'Ended',
  Paused = 'Paused'
}
