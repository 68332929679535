import { TaskType } from '../app/data/models/task-type';
import { BillingType } from '../app/data/models';
import { ScheduleSettings } from '@triarc-labs/tl-planning-canvas';

export const commonEnvironment = {
  showCreatePdfButton: true,
  navbarColor: '#333333',
  preventFormUnload: false,
  driverSettings: {
    reloadInterval: 60000,
    requestRefreshInterval: 15 * 60,
    requestDriverConfirmation: true,
  },
  trailerOptions: [
    'AZ',
    'DT',
    'DL',
    'MT',
    'Rampe',
    'Spedi',
    'TB',
    'VP2',
    'VP5',
    'VP7',
    'MPA2',
    'MPA4',
    'Kippsattel',
    'Mischersattel',
  ],
  skipMaterials: [
    'Aushub',
    'Felsmaterial',
    'Aushub Kiesig',
    'Humus',
    'Betonabbruch',
    'Mischabbruch',
    'Felsmaterial zum Brechen',
    'Inert Typ B',
    'Reaktormaterial',
    'Belagsaufbruch',
    'Holz',
    'KVA Brennbar',
    'Bausperrgut',
    'Alteisen',
    'Wurzelstöcke',
    'Grüngut / Äste',
    'Eisenbahn-schwellen',
    'Ziegel',
    'RC Betongranulat 0-45',
    'UG 0-45',
    'Kiessand',
    'Rundkies 8-16',
    'Sickerbölli 32-45',
    'Diverse Inhalte',
    'Deckenschalmaterial',
    'Elemente',
    'Framax',
    'Material gemäss Angaben BST',
    'Baumaschinen',
    'Treppenelement',
    'Kranteile',
    'Gerüstmaterial',
  ],
  cargoMaterials: [
    'Deckenschalmaterial',
    'Elemente',
    'Framax',
    'Material gemäss Angaben BST',
    'Baumaschinen',
    'Treppenelement',
    'Brecher',
    'Kranteile',
    'Gerüstmaterial',
  ],
  defaultAccentColor: 0x5d9528,
  defaultAccentColorStr: '#5d9528',
  showTaskDetailDebugInformation: false,
  showDriverReportDebugInformation: true,
  sentry: undefined as string | undefined,
  defaultTenant: 3550,
  updatePollingInterval: 60,
  updatePollingIntervalDelay: 10,
  updateVehiclePollingInterval: 60,
  keycloakCordovaAdapter: 'cordova',
  redirectUrl: undefined as string | undefined,
  messageCenter: {
    counterRefreshTime: 30000,
    messageRefreshTime: 30000,
    perPage: 8,
  },
  bearerExcludedUrls: ['maps.googleapis.com'],
  planning: {},
  version: '0.0.0',
  authServerRealm: 'christen',
  authServerClient: 'christen-cal-client',
  workJournal: {
    refreshTime: 60000, // 60s
  },
  tabletScreenSize: 1024,
  enableRealtimeConnection: true,
  hoursPerWorkingDay: 8.5,
  calScheduleSettings: {
    workPackageMinimalBreakingPointWidth: 300,
    useMockRequests: false,
    rowGroupHeight: 30,
    iconSizePx: 28,
    drawRoutes: false,
    rowGroupBackgroundColor: 0xeff9f1,
    travelBarHeight: 10,
  },
  scheduleSettings: {
    assetsToLoad: ['assets/images/bubble_right.png', 'assets/images/bubble_left.png'],
    planningObjectFont: 'arial',
    contextMenuWidth: 252,
    selectionPanelWidth: 600,
    sectionsPreloadDepth: 2,
    sectionsViewPortDepth: 1,
    rowsBarWidth: 300,
    timeBarHeight: 80,
    planningObjectHeight: 46,
    planningObjectSeparatorHeight: 6,
    planningObjectCornerRadius: 5,
    planningObjectStubWidth: 30,
    minimumHourPixels: 500,
    minimumDayNamesPixels: 1000,
    minimumWeekNamesPixels: 1100,
    planningObjectClickRadius: 8,
    planningObjectDragDecoratorRadius: 8,
    planningObjectDragDecoratorColor: 0x999999,
    planningObjectDragDecoratorClickRadius: 14,
    planningObjectMinimumWidth: 60,
    planningObjectStartStopWidth: 32,
    planningObjectStartStopColor: 0x97d781,
    workHours: {
      from: 6,
      to: 19,
      enabled: true,
    },
    borderSpeedInitial: 8,
    borderSpeedMax: 60,
    borderSpeedInterval: 40,
    borderSpeedGrowth: 1.5,
    borderBottomRange: 150,
    inertiaTimeThreshold: 120,
    inertiaSpeedInterval: 35,
    inertiaSpeedFactor: 0.75,
    inertiaMinimumDistance: 20,
    linesColor: 0xe4e4e4,
    rowsBarLinesColorSelected: 0x3a3a3a,
    showScheduleCustomActions: false,
    scheduleHourScale: 10,
    scheduleDayScale: 2.6,
    scheduleWeekScale: 0.4,
    scheduleMonthScale: 0.1,
    maxScale: 15,
    minScale: 0.08,
    timeBarBackgroundColor: 0xeff9f1,
    timeBarTodayBackgroundColor: 0xfcebdc,
    useWebGpu: false,
    continuousPlanningMode: false,
    isBorderScrollEnabled: true,
    isDragNDropEnabled: true,
  } satisfies ScheduleSettings,
  vehicleCategories: {
    [TaskType.Kipper]: '1',
    [TaskType.Mischer]: '2',
    [TaskType.Schlepper]: '3',
    [TaskType.MobilKran]: '5',
    [TaskType.LkwKran]: '6',
    [TaskType.Hakengerat]: '7',
    [TaskType.Winterdienst]: '',
    [TaskType.FuhrleistungExtern]: '',
    [TaskType.FuhrleistungIntern]: '',
    [TaskType.InstallationsPauschale]: '*',
    [TaskType.Hebeleistung]: '5',
  },
  hakengeratCategoryWelaki: '7300',
  googleMapsApiKey: 'AIzaSyChC9djGalEJRbGbtES8Rm-o0akoPQV0H0',
  agGridKey:
    'Using_this_{AG_Grid}_Enterprise_key_{AG-069213}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{triarc_laboratories_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Christen_Advances_Logistics}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Christen_Advances_Logistics}_need_to_be_licensed___{Christen_Advances_Logistics}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_December_2025}____[v3]_[01]_MTc2NTQxMTIwMDAwMA==ca34f5301b20151b6f2c59e36fe8c065',
  materialByTaskType: {
    [TaskType.Hebeleistung]: {
      material: 'A|CAL_DivHebel|0',
      amount: null,
      billingType: null,
    },
    [TaskType.InstallationsPauschale]: {
      material: 'A|CAL_Installationspauschale|0',
      amount: 1,
      billingType: BillingType.Fixed,
    },
  } as {
    [key in TaskType]?: {
      material: string;
      amount: number | null;
      billingType: BillingType | null;
    };
  },
  muldenProzessMaterialId: 'A|CAL_DivMulde|0',
  depotExternalAddressId: '1',
  materialExternalIds: [
    'A|CAL_DivHebelA|0',
    'A|CAL_Installationspauschale|0',
    'A|CAL_DivHebelZ|0',
    'A|CAL_DivHebel|0',
  ], // IDs for which the material is hidden in the header
  routerEnableTracing: false,
  firebaseConfig: {},
  firebaseWebConfig: {
    vapidKey:
      'BKsOs9nwq73-g4LdZFgiugk9GQFJM6DCNVFotWKI83w2_3PfBvkJ--WOo8HmmKkpjUjFZbyxQVDoOB8IOdGuugo',
  },
  vehicleCategoryGroupSort: [
    { range: [0, 999] },
    { range: [1000, 1999] },
    { range: [7000, 7999] },
    { range: [2000, 2999] },
    { range: [3000, 3999] },
    { range: [5000, 5999] },
    { range: [6000, 6999] },
  ],
};
